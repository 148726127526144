<template>
  <div class="page">
    <b-card>
      <vue-dropzone
        id="media_dropzone"
        ref="myVueDropzone"
        :options="dropzoneOptions"
        @vdropzone-files-added="filesAdded"
      />
    </b-card>
    <b-card>
      <div
        class="d-flex justify-content-end align-items-center mb-2"
      >
        <div
          class="d-flex w-100 justify-content-between"
        >
          <div class="d-flex">
            <b-input-group
              class="input-group-merge"
              style="max-width: 300px;"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="search"
                placeholder="Search by title"
                @input="onSearch"
              />
            </b-input-group>
          </div>
        </div>
      </div>

      <div>
        <div class="row">
          <div
            v-for="(singleImage, index) in getImageList"
            :key="index"
            class="singleImage col-lg-3 my-1"
            @click="onSingleImageClick(singleImage)"
          >
            <img
              :src="singleImage.file_path"
              :alt="singleImage.title"
            >

            <b-avatar
              class="deleteImgBtnContainer"
            >
              <b-button
                class="deleteImgBtn"
                @click="e => onDeleteBtnClick(e, index)"
              >
                <feather-icon
                  icon="XIcon"
                  size="25"
                />
              </b-button>
            </b-avatar>
          </div>
        </div>
      </div>
    </b-card>

    <b-modal
      id="edit-image-modal"
      size="sm"
      ok-variant="outline-primary"
      cancel-variant="outline-danger"
      @ok="e => handleOk(e)"
      @cancel="handleCancel"
    >
      <div
        v-if="Object.keys(getTargetItem).length"
        class="w-100"
      >
        <img
          class="w-100 m-auto"
          :src="getTargetItem.file_path"
          :alt="getTargetItem.title"
        >

        <label class="mt-2">
          File Name
        </label>

        <b-form-input
          v-model="getTargetItem.title"
          placeholder="File Name"
        />

        <label class="mt-2">
          File URL
        </label>
        <b-form-input
          v-model="getTargetItem.file_path"
          readonly
        />

        <label class="mt-2">
          Description
        </label>
        <b-form-textarea
          v-model="getTargetItem.description"
          placeholder="Description"
          rows="5"
          max-rows="8"
        />
      </div>
    </b-modal>

  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BCard,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BModal,
  BFormTextarea,
  BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BModal,
    BFormTextarea,
    BAvatar,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 300,
        maxFilesize: 1,
        headers: { 'My-Awesome-Header': 'header value' },
        acceptedFiles: 'image/*',
        addRemoveLinks: false,
      },
      imageFileList: [],
      imageList: [],
      search: '',
      isShowDropzone: false,
      targetIndex: 0,
      mediaTotal: 0,
      mediaLazyLoadIndex: 1,
      mediaLastPage: 1,
      targetItem: {},
    }
  },
  computed: {
    getClient() {
      return this.$store.state.clientInfo
    },
    getImageList() {
      return this.imageList
    },
    getMaxFileSize() {
      return this.$store.state.MAX_FILE_UPLOAD_SIZE
    },
    getTargetItem() {
      return this.targetItem
    },
  },
  created() {
    this.$store.dispatch('media/getPhotos', { account_id: this.getClient.associated_id, keyword: '' }).then(response => {
      this.imageList = response.data.data
      this.mediaTotal = response.data.total
      this.mediaLastPage = response.data.last_page
    }).catch(err => {
      console.error(err)
    })
  },
  mounted() {
    this.onWindowScroll()
  },
  methods: {
    getScrollXY() {
      let scrOfX = 0; let
        scrOfY = 0
      if (typeof (window.pageYOffset) === 'number') {
        // Netscape compliant
        scrOfY = window.pageYOffset
        scrOfX = window.pageXOffset
      } else if (document.body && (document.body.scrollLeft || document.body.scrollTop)) {
        // DOM compliant
        scrOfY = document.body.scrollTop
        scrOfX = document.body.scrollLeft
      } else if (document.documentElement && (document.documentElement.scrollLeft || document.documentElement.scrollTop)) {
        // IE6 standards compliant mode
        scrOfY = document.documentElement.scrollTop
        scrOfX = document.documentElement.scrollLeft
      }
      return [scrOfX, scrOfY]
    },
    getDocHeight() {
      const D = document
      return Math.max(
        D.body.scrollHeight, D.documentElement.scrollHeight,
        D.body.offsetHeight, D.documentElement.offsetHeight,
        D.body.clientHeight, D.documentElement.clientHeight,
      )
    },
    onWindowScroll() {
      document.addEventListener('scroll', () => {
        if (this.getDocHeight() === this.getScrollXY()[1] + window.innerHeight && this.mediaLazyLoadIndex < this.mediaLastPage) {
          this.mediaLazyLoadIndex++
          this.onPageChange(this.mediaLazyLoadIndex)
        }
      })
    },
    onSingleImageClick(item) {
      // this.targetIndex = i
      this.targetItem = item
      this.$bvModal.show('edit-image-modal')
    },
    onSearch() {
      this.$store.dispatch('media/getPhotos', { account_id: this.getClient.associated_id, keyword: this.search }).then(response => {
        this.imageList = response.data.data
        this.mediaTotal = response.data.total
      }).catch(err => {
        console.error(err)
      })
    },
    filesAdded(file) {
      file.forEach(data => {
        if (data.size < 1024 * this.getMaxFileSize) {
          const formData = new FormData()
          formData.append('account_id', this.getClient.associated_id)
          formData.append('image', data)

          this.$store.dispatch('media/uploadPhoto', formData).then(response => {
            if (response.code === 200) {
              this.imageList.push(response.data)
              this.mediaTotal++
              this.$bvModal.hide('uplaod-media-modal')
              this.showToast('Success', 'CheckCircleIcon', response.data.title + ' has been uploaded successfully', 'success', 'bottom-right')
            }
          }).catch(err => {
            console.error(err)
          })
        } else {
          this.$refs.myVueDropzone.removeFile(data)

          this.showToast('Upload Failed', 'AlertCircleIcon', data.name + ' exceeds the maximum file size (1MB)', 'danger', 'bottom-right')
        }
      })
    },
    onPageChange(p) {
      this.$store.dispatch('media/getPhotos', { page: p, account_id: this.getClient.associated_id, keyword: this.search }).then(response => {
        if (response.code === 200) {
          response.data.data.forEach(element => {
            this.imageList.push(element)
          })
        }
      }).catch(err => {
        console.error(err)
      })
    },
    onDeleteBtnClick(e, i) {
      e.stopPropagation()
      this.$store.dispatch('media/deletePhoto', { account_id: this.getClient.associated_id, photo_id: this.getImageList[i].id }).then(response => {
        if (response.code === 200) {
          this.showToast('Success', 'CheckCircleIcon', this.getImageList[i].title + ' has been deleted successfully', 'success', 'bottom-right')
          this.imageList.splice(i, 1)
          this.mediaTotal--
        }
      }).catch(err => {
        console.error(err)
      })
    },
    handleOk(e) {
      e.preventDefault()

      if (Object.keys(this.targetItem).length) {
        const data = {
          account_id: this.targetItem.account_id,
          photo_id: this.targetItem.id,
          description: this.targetItem.description,
          title: this.targetItem.title,
        }

        this.$store.dispatch('media/updatePhoto', data).then(response => {
          if (response.code === 200) {
            const targetIndex = this.getImageList.findIndex(i => i.id === response.data.id)
            this.getImageList[targetIndex] = response.data

            this.showToast('Success', 'CheckCircleIcon', response.data.title + ' has been updated successfully', 'success', 'bottom-right')
            this.$bvModal.hide('edit-image-modal')
            this.targetItem = {}
          } else {
            this.showToast('Update Failed', 'AlertCircleIcon', 'There was error(s) during update, please try again later', 'danger', 'bottom-right')
          }
        }).catch(err => {
          console.error(err)
        })
      }
    },
    handleCancel() {
      this.targetItem = {}
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style>
.vs__open-indicator{
  display:none !important;
}

.dz-remove {
  top: unset !important;
}

.dz-message span{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.singleImage {
  cursor: pointer;
  position: relative;
}

.singleImage img {
  max-width: 100%;
  width: 100%;
}

.singleImage .deleteImgBtnContainer {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  color: #fff !important;
}

.singleImage .deleteImgBtnContainer .deleteImgBtn{
  background: rgba(0,0,0, .5) !important;
}

.singleImage .deleteImgBtnContainer .deleteImgBtn:hover{
  background: rgba(0,0,0, 1) !important;
}

#media_dropzone {
    min-height: 350px;
}
</style>
